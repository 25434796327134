import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_sorokins_in_national_team" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Полузащитник ФК Мантова Алексейс Савельевс и защитник ФК РФС (Рига) Владиславс Сорокинс, вызваны в Национальную сборную Латвии. У сборной Латвии запланирована одна товарищеская игра со сборной Сан-Марино и две официальные игры со сборной Фарерских остров и сборной Андорры.</p>
        </div>
      </div>
    )
  }
}